import { InputLabel, makeStyles } from "@material-ui/core"
import React from "react"
import Select from "react-select"

const useStyle = makeStyles(theme => ({
  root: {
    marginLeft: "5px",
    padding: "5px",
    display: "flex",
    flexDirection: "row",
    paddingRight: theme.spacing(12),
    marginTop: "-4px",
    minHeight: "52px",
    cursor: "pointer",
  },
}))

function SelectWithIcon({
  placeHolder,
  options,
  label,
  onChange,
  value,
  errors,
  getOptionLabel,
  getOptionValue,
  components,
}) {
  const classes = useStyle()

  const style = {
    control: (base, state) => ({
      ...base,

      fontSize: "12px",
      display: "flex",
      flexGrow: 1,
      position: "relative",
      width: "100%",
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "10px",
      marginRight: "-50px",
      marginLeft: "-10px",
      borderRadius: 10,
      border: "1.5px solid white",
    }),
  }

  const errorstyle = {
    control: (base, state) => ({
      ...base,
      border: 0,
      fontSize: "12px",
      display: "flex",
      flexGrow: 1,
      position: "relative",
      cursor: "pointer",
      width: "100%",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "10px",
      marginRight: "-50px",
      marginLeft: "-10px",
      borderRadius: 10,
      borderColor: state.isFocused ? "transparent" : "yellow",
      border: "1.5px solid #FD6E84 ",
    }),
  }

  const handleChange = e => {
    onChange(e)
  }

  return (
    <>
      {label && (
        <InputLabel shrink style={{ color: "#0A3133", marginBottom: "10px" }}>
          {label}
        </InputLabel>
      )}
      <Select
        placeholder={placeHolder}
        value={value}
        options={options}
        className={`${classes.root} racqy-select-input`}
        isSearchable={true}
        isClearable={true}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        styles={errors ? errorstyle : style}
        components={components}
      />
    </>
  )
}

export default SelectWithIcon
